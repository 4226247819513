import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // redirect: "/anjungan-mandiri/main",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
        meta: {
          title: "SIDARIN - Dashboard",
          access_right: "1001",
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue"),
      },

      // citizen-report
      {
        path: "/citizen-report",
        name: "citizen-report",
        redirect: "/citizen-report/list",
        component: () =>
          import("@/view/pages/citizen-report/CitizenReport.vue"),
        meta: {
          title: "SIDARIN | Data Pengaduan Masyarakat",
        },
        children: [
          // setting
          {
            path: "list",
            name: "citizen-report-list",
            component: () => import("@/view/pages/citizen-report/List.vue"),
            meta: {
              title: "SIDARIN | Data Pengaduan Masyarakat",
            },
          },
          {
            path: "add",
            name: "citizen-report-add",
            component: () => import("@/view/pages/citizen-report/Add.vue"),
            meta: {
              title: "SIDARIN | Data Pengaduan Masyarakat",
            },
          },
          {
            path: "edit/:id",
            name: "citizen-report-edit",
            component: () => import("@/view/pages/citizen-report/Edit.vue"),
            meta: {
              title: "SIDARIN | Data Pengaduan Masyarakat",
            },
          },
        ],
      },

      // citizen
      {
        path: "/citizen",
        name: "citizen",
        redirect: "/citizen/list",
        component: () => import("@/view/pages/citizen/Citizen.vue"),
        meta: {
          title: "SIDARIN | Data Penduduk",
        },
        children: [
          // setting
          {
            path: "list",
            name: "citizen-list",
            component: () => import("@/view/pages/citizen/List.vue"),
            meta: {
              title: "SIDARIN | Data Kependudukan",
            },
          },
          {
            path: "add",
            name: "citizen-add",
            component: () => import("@/view/pages/citizen/Add.vue"),
            meta: {
              title: "SIDARIN | Data Kependudukan",
            },
          },
          {
            path: "edit/:id",
            name: "citizen-edit",
            component: () => import("@/view/pages/citizen/Edit.vue"),
            meta: {
              title: "SIDARIN | Data Kependudukan",
            },
          },
        ],
      },

      // village-business
      {
        path: "/village-business",
        name: "village-business",
        redirect: "/village-business/list",
        component: () =>
          import("@/view/pages/village-business/VillageBusiness.vue"),
        meta: {
          title: "SIDARIN | Tempat Usaha",
        },
        children: [
          // setting
          {
            path: "list",
            name: "village-business-list",
            component: () => import("@/view/pages/village-business/List.vue"),
            meta: {
              title: "SIDARIN | Tempat Usaha",
            },
          },
          {
            path: "add",
            name: "village-business-add",
            component: () => import("@/view/pages/village-business/Add.vue"),
            meta: {
              title: "SIDARIN | Tempat Usaha",
            },
          },
          {
            path: "edit/:id",
            name: "village-business-edit",
            component: () => import("@/view/pages/village-business/Edit.vue"),
            meta: {
              title: "SIDARIN | Tempat Usaha",
            },
          },
          {
            path: "detail/:id",
            name: "village-business-detail",
            component: () => import("@/view/pages/village-business/Detail.vue"),
            meta: {
              title: "SIMDES | Tempat Usaha",
            },
          },
        ],
      },
      // letters
      {
        path: "/letters",
        name: "letters",
        redirect: "/letters/data",
        component: () => import("@/view/pages/letters/Letter.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          // setting
          {
            path: "/letters/data",
            name: "letters-data",
            component: () => import("@/view/pages/letters/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Buat Surat",
            },
          },
          {
            path: "/letters/add",
            name: "letters-add",
            component: () => import("@/view/pages/letters/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Buat Surat",
            },
          },
        ],
      },
      {
        path: "/poverty-certificate",
        name: "poverty-certificate",
        redirect: "/poverty-certificate/list",
        component: () =>
          import("@/view/pages/poverty-certificate/PovertyCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/poverty-certificate/list",
            name: "poverty-certificate-list",
            component: () =>
              import("@/view/pages/poverty-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kurang Mampu",
            },
          },
          {
            path: "/poverty-certificate/add",
            name: "poverty-certificate-add",
            component: () => import("@/view/pages/poverty-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kurang Mampu",
            },
          },
          {
            path: "/poverty-certificate/edit/:id",
            name: "poverty-certificate-edit",
            component: () =>
              import("@/view/pages/poverty-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kurang Mampu",
            },
          },
          {
            path: "/poverty-certificate/detail/:id",
            name: "poverty-certificate-detail",
            component: () =>
              import("@/view/pages/poverty-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kurang Mampu",
            },
          },
        ],
      },
      {
        path: "/death-certificate",
        name: "death-certificate",
        redirect: "/death-certificate/list",
        component: () =>
          import("@/view/pages/death-certificate/DeathCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/death-certificate/list",
            name: "death-certificate-list",
            component: () => import("@/view/pages/death-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kematian",
            },
          },
          {
            path: "/death-certificate/add",
            name: "death-certificate-add",
            component: () => import("@/view/pages/death-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kematian",
            },
          },
          {
            path: "/death-certificate/edit/:id",
            name: "death-certificate-edit",
            component: () => import("@/view/pages/death-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kematian",
            },
          },
          {
            path: "/death-certificate/detail/:id",
            name: "death-certificate-detail",
            component: () =>
              import("@/view/pages/death-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kematian",
            },
          },
        ],
      },
      {
        path: "/bussiness-certificate",
        name: "bussiness-certificate",
        redirect: "/bussiness-certificate/list",
        component: () =>
          import("@/view/pages/bussiness-certificate/BussinessCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/bussiness-certificate/list",
            name: "bussiness-certificate-list",
            component: () =>
              import("@/view/pages/bussiness-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Usaha",
            },
          },
          {
            path: "/bussiness-certificate/add",
            name: "bussiness-certificate-add",
            component: () =>
              import("@/view/pages/bussiness-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Usaha",
            },
          },
          {
            path: "/bussiness-certificate/edit/:id",
            name: "bussiness-certificate-edit",
            component: () =>
              import("@/view/pages/bussiness-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Usaha",
            },
          },
          {
            path: "/bussiness-certificate/detail/:id",
            name: "bussiness-certificate-detail",
            component: () =>
              import("@/view/pages/bussiness-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Usaha",
            },
          },
        ],
      },
      {
        path: "/transfer-certificate",
        name: "transfer-certificate",
        redirect: "/transfer-certificate/list",
        component: () =>
          import("@/view/pages/transfer-certificate/TransferCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/transfer-certificate/list",
            name: "transfer-certificate-list",
            component: () =>
              import("@/view/pages/transfer-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Pindah",
            },
          },
          {
            path: "/transfer-certificate/add",
            name: "transfer-certificate-add",
            component: () =>
              import("@/view/pages/transfer-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Pindah",
            },
          },
          {
            path: "/transfer-certificate/edit/:id",
            name: "transfer-certificate-edit",
            component: () =>
              import("@/view/pages/transfer-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Pindah",
            },
          },
          {
            path: "/transfer-certificate/detail/:id",
            name: "transfer-certificate-detail",
            component: () =>
              import("@/view/pages/transfer-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Pindah",
            },
          },
        ],
      },
      {
        path: "/birth-certificate",
        name: "birth-certificate",
        redirect: "/birth-certificate/list",
        component: () =>
          import("@/view/pages/birth-certificate/BirthCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/birth-certificate/list",
            name: "birth-certificate-list",
            component: () => import("@/view/pages/birth-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kelahiran",
            },
          },
          {
            path: "/birth-certificate/add",
            name: "birth-certificate-add",
            component: () => import("@/view/pages/birth-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kelahiran",
            },
          },
          {
            path: "/birth-certificate/edit/:id",
            name: "birth-certificate-edit",
            component: () => import("@/view/pages/birth-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kelahiran",
            },
          },
          {
            path: "/birth-certificate/detail/:id",
            name: "birth-certificate-detail",
            component: () =>
              import("@/view/pages/birth-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Kelahiran",
            },
          },
        ],
      },
      {
        path: "/unmarried-certificate",
        name: "unmarried-certificate",
        redirect: "/unmarried-certificate/list",
        component: () =>
          import("@/view/pages/unmarried-certificate/UnmarriedCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/unmarried-certificate/list",
            name: "unmarried-certificate-list",
            component: () =>
              import("@/view/pages/unmarried-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Belum Kawin",
            },
          },
          {
            path: "/unmarried-certificate/add",
            name: "unmarried-certificate-add",
            component: () =>
              import("@/view/pages/unmarried-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Belum Kawin",
            },
          },
          {
            path: "/unmarried-certificate/edit/:id",
            name: "unmarried-certificate-edit",
            component: () =>
              import("@/view/pages/unmarried-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Belum Kawin",
            },
          },
          {
            path: "/unmarried-certificate/detail/:id",
            name: "unmarried-certificate-detail",
            component: () =>
              import("@/view/pages/unmarried-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Belum Kawin",
            },
          },
        ],
      },
      {
        path: "/resident-certificate",
        name: "resident-certificate",
        redirect: "/resident-certificate/list",
        component: () =>
          import("@/view/pages/resident-certificate/ResidentCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/resident-certificate/list",
            name: "resident-certificate-list",
            component: () =>
              import("@/view/pages/resident-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan",
            },
          },
          {
            path: "/resident-certificate/add",
            name: "resident-certificate-add",
            component: () =>
              import("@/view/pages/resident-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan",
            },
          },
          {
            path: "/resident-certificate/edit/:id",
            name: "resident-certificate-edit",
            component: () =>
              import("@/view/pages/resident-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan",
            },
          },
          {
            path: "/resident-certificate/detail/:id",
            name: "resident-certificate-detail",
            component: () =>
              import("@/view/pages/resident-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan",
            },
          },
        ],
      },
      {
        path: "/cover-letter",
        name: "cover-letter",
        redirect: "/cover-letter/list",
        component: () => import("@/view/pages/cover-letter/CoverLetter.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/cover-letter/list",
            name: "cover-letter-list",
            component: () => import("@/view/pages/cover-letter/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Pengantar KTP",
            },
          },
          {
            path: "/cover-letter/add",
            name: "cover-letter-add",
            component: () => import("@/view/pages/cover-letter/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Pengantar KTP",
            },
          },
          {
            path: "/cover-letter/edit/:id",
            name: "cover-letter-edit",
            component: () => import("@/view/pages/cover-letter/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Pengantar KTP",
            },
          },
          {
            path: "/cover-letter/detail/:id",
            name: "cover-letter-detail",
            component: () => import("@/view/pages/cover-letter/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Pengantar KTP",
            },
          },
        ],
      },
      {
        path: "/bussiness-place-certificate",
        name: "bussiness-place-certificate",
        redirect: "/bussiness-place-certificate/list",
        component: () =>
          import(
            "@/view/pages/bussiness-place-certificate/BussinessPlaceCertificate.vue"
          ),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/bussiness-place-certificate/list",
            name: "bussiness-place-certificate-list",
            component: () =>
              import("@/view/pages/bussiness-place-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Tempat Usaha",
            },
          },
          {
            path: "/bussiness-place-certificate/add",
            name: "bussiness-place-certificate-add",
            component: () =>
              import("@/view/pages/bussiness-place-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Tempat Usaha",
            },
          },
          {
            path: "/bussiness-place-certificate/edit/:id",
            name: "bussiness-place-certificate-edit",
            component: () =>
              import("@/view/pages/bussiness-place-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Tempat Usaha",
            },
          },
          {
            path: "/bussiness-place-certificate/detail/:id",
            name: "bussiness-place-certificate-detail",
            component: () =>
              import("@/view/pages/bussiness-place-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan Tempat Usaha",
            },
          },
        ],
      },
      {
        path: "/good-conduct-certificate",
        name: "good-conduct-certificate",
        redirect: "/good-conduct-certificate/list",
        component: () =>
          import(
            "@/view/pages/good-conduct-certificate/GoodConductCertificate.vue"
          ),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/good-conduct-certificate/list",
            name: "good-conduct-certificate-list",
            component: () =>
              import("@/view/pages/good-conduct-certificate/List.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Kelakuan Baik",
            },
          },
          {
            path: "/good-conduct-certificate/add",
            name: "good-conduct-certificate-add",
            component: () =>
              import("@/view/pages/good-conduct-certificate/Add.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Kelakuan Baik",
            },
          },
          {
            path: "/good-conduct-certificate/edit/:id",
            name: "good-conduct-certificate-edit",
            component: () =>
              import("@/view/pages/good-conduct-certificate/Edit.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Kelakuan Baik",
            },
          },
          {
            path: "/good-conduct-certificate/detail/:id",
            name: "good-conduct-certificate-detail",
            component: () =>
              import("@/view/pages/good-conduct-certificate/Detail.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Kelakuan Baik",
            },
          },
        ],
      },
      {
        path: "/health-insurance-certificate",
        name: "health-insurance-certificate",
        redirect: "/health-insurance-certificate/list",
        component: () =>
          import(
            "@/view/pages/health-insurance-certificate/HealthInsuranceCertificate.vue"
          ),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/health-insurance-certificate/list",
            name: "health-insurance-certificate-list",
            component: () =>
              import("@/view/pages/health-insurance-certificate/List.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Jaminan Kesehatan",
            },
          },
          {
            path: "/health-insurance-certificate/add",
            name: "health-insurance-certificate-add",
            component: () =>
              import("@/view/pages/health-insurance-certificate/Add.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Jaminan Kesehatan",
            },
          },
          {
            path: "/health-insurance-certificate/edit/:id",
            name: "health-insurance-certificate-edit",
            component: () =>
              import("@/view/pages/health-insurance-certificate/Edit.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Jaminan Kesehatan",
            },
          },
          {
            path: "/health-insurance-certificate/detail/:id",
            name: "health-insurance-certificate-detail",
            component: () =>
              import("@/view/pages/health-insurance-certificate/Detail.vue"),
            meta: {
              title:
                "SIDARIN | Surat Menyurat - Surat Keterangan Jaminan Kesehatan",
            },
          },
        ],
      },
      {
        path: "/jkbm-certificate",
        name: "jkbm-certificate",
        redirect: "/jkbm-certificate/list",
        component: () =>
          import("@/view/pages/jkbm-certificate/JkbmCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/jkbm-certificate/list",
            name: "jkbm-certificate-list",
            component: () => import("@/view/pages/jkbm-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan JKBM",
            },
          },
          {
            path: "/jkbm-certificate/add",
            name: "jkbm-certificate-add",
            component: () => import("@/view/pages/jkbm-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan JKBM",
            },
          },
          {
            path: "/jkbm-certificate/edit/:id",
            name: "jkbm-certificate-edit",
            component: () => import("@/view/pages/jkbm-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan JKBM",
            },
          },
          {
            path: "/jkbm-certificate/detail/:id",
            name: "jkbm-certificate-detail",
            component: () => import("@/view/pages/jkbm-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Keterangan JKBM",
            },
          },
        ],
      },
      {
        path: "/general-certificate",
        name: "general-certificate",
        redirect: "/general-certificate/list",
        component: () =>
          import("@/view/pages/general-certificate/GeneralCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/general-certificate/list",
            name: "general-certificate-list",
            component: () =>
              import("@/view/pages/general-certificate/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Umum",
            },
          },
          {
            path: "/general-certificate/add",
            name: "general-certificate-add",
            component: () =>
              import("@/view/pages/general-certificate/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Umum",
            },
          },
          {
            path: "/general-certificate/edit/:id",
            name: "general-certificate-edit",
            component: () =>
              import("@/view/pages/general-certificate/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Umum",
            },
          },
          {
            path: "/general-certificate/detail/:id",
            name: "general-certificate-detail",
            component: () =>
              import("@/view/pages/general-certificate/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Surat Umum",
            },
          },
        ],
      },
      {
        path: "/law-products",
        name: "law-products",
        redirect: "/law-products/list",
        component: () => import("@/view/pages/law-products/LawProduct.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/law-products/list",
            name: "law-products-list",
            component: () => import("@/view/pages/law-products/List.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Produk Hukum",
            },
          },
          {
            path: "/law-products/add",
            name: "law-products-add",
            component: () => import("@/view/pages/law-products/Add.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Produk Hukum",
            },
          },
          {
            path: "/law-products/edit/:id",
            name: "law-products-edit",
            component: () => import("@/view/pages/law-products/Edit.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Produk Hukum",
            },
          },
          {
            path: "/law-products/detail/:id",
            name: "law-products-detail",
            component: () => import("@/view/pages/law-products/Detail.vue"),
            meta: {
              title: "SIDARIN | Surat Menyurat - Produk Hukum",
            },
          },
        ],
      },

      // settings
      {
        path: "/settings",
        name: "settings",
        redirect: "/settings/edit/:id",
        component: () => import("@/view/pages/settings/Setting.vue"),
        meta: {
          title: "SIDARIN | Surat Menyurat",
        },
        children: [
          {
            path: "/settings/edit/:id",
            name: "settings-edit/:id",
            component: () => import("@/view/pages/settings/Edit.vue"),
            meta: {
              title: "SIDARIN | Pengaturan",
            },
          },
        ],
      },

      // statistic
      {
        path: "/statistic",
        name: "statistic",
        redirect: "/statistic/show",
        component: () => import("@/view/pages/statistic/Statistic.vue"),
        meta: {
          title: "SIDARIN | Statistik",
        },
        children: [
          {
            path: "/statistic/show",
            name: "statistic",
            component: () => import("@/view/pages/statistic/Main.vue"),
            meta: {
              title: "SIDARIN | Statistik",
            },
          },
        ],
      },

      // News
      {
        path: "/infrastructure",
        name: "infrastructure",
        redirect: "/list",
        component: () => import("@/view/pages/infrastructure/Page.vue"),
        meta: {
          title: "SIDARIN | Sarana & Prasarana",
        },
        children: [
          // setting
          {
            path: "/list",
            name: "list",
            component: () =>
              import("@/view/pages/infrastructure/Infrastructure.vue"),
            meta: {
              title: "SIDARIN | Sarana & Prasarana",
            },
          },
        ],
      },

      // News
      {
        path: "/website",
        name: "website",
        redirect: "/website-news",
        component: () => import("@/view/pages/website/Websites.vue"),
        meta: {
          title: "SIDARIN | Berita Desa",
        },
        children: [
          // setting
          {
            path: "/website-news",
            name: "website-news",
            component: () => import("@/view/pages/website/News.vue"),
            meta: {
              title: "SIDARIN | Berita Desa - Daftar Berita",
            },
          },
          {
            path: "/village-info",
            name: "village-info",
            component: () => import("@/view/pages/website/VillageInfo.vue"),
            meta: {
              title: "SIDARIN | Info Desa",
            },
          },
          // {
          //   path: "/citizen-report",
          //   name: "citizen-report",
          //   component: () => import("@/view/pages/website/CitizenReported.vue"),
          //   meta: {
          //     title: 'SIDARIN | Pengaduan Masyarakat'
          //   },
          // },
        ],
      },

      // uoms
      {
        path: "/uoms",
        name: "uoms",
        redirect: "/uoms/list",
        component: () =>
          import("@/view/pages/uoms/Uom.vue"),
        meta: {
          title: "SIDARIN | Satuan",
        },
        children: [
          // setting
          {
            path: "list",
            name: "uoms-list",
            component: () => import("@/view/pages/uoms/List.vue"),
            meta: {
              title: "SIDARIN | Satuan",
            },
          },
          {
            path: "add",
            name: "uoms-add",
            component: () => import("@/view/pages/uoms/Add.vue"),
            meta: {
              title: "SIDARIN | Satuan",
            },
          },
          {
            path: "edit/:id",
            name: "uoms-edit",
            component: () => import("@/view/pages/uoms/Edit.vue"),
            meta: {
              title: "SIDARIN | Satuan",
            },
          },
          {
            path: "detail/:id",
            name: "uoms-detail",
            component: () => import("@/view/pages/uoms/Detail.vue"),
            meta: {
              title: "SIMDES | Satuan",
            },
          },
        ],
      },

      // inventory-categories
      {
        path: "/inventory-categories",
        name: "inventory-categories",
        redirect: "/inventory-categories/list",
        component: () =>
          import("@/view/pages/inventory-categories/InventoryCategory.vue"),
        meta: {
          title: "SIDARIN | Kategori Inventori",
        },
        children: [
          // setting
          {
            path: "list",
            name: "inventory-categories-list",
            component: () => import("@/view/pages/inventory-categories/List.vue"),
            meta: {
              title: "SIDARIN | Kategori Inventori",
            },
          },
          {
            path: "add",
            name: "inventory-categories-add",
            component: () => import("@/view/pages/inventory-categories/Add.vue"),
            meta: {
              title: "SIDARIN | Kategori Inventori",
            },
          },
          {
            path: "edit/:id",
            name: "inventory-categories-edit",
            component: () => import("@/view/pages/inventory-categories/Edit.vue"),
            meta: {
              title: "SIDARIN | Kategori Inventori",
            },
          },
          {
            path: "detail/:id",
            name: "inventory-categories-detail",
            component: () => import("@/view/pages/inventory-categories/Detail.vue"),
            meta: {
              title: "SIMDES | Kategori Inventori",
            },
          },
        ],
      },

      // inventories
      {
        path: "/inventories",
        name: "inventories",
        redirect: "/inventories/list",
        component: () =>
          import("@/view/pages/inventories/Inventory.vue"),
        meta: {
          title: "SIDARIN | Inventori",
        },
        children: [
          // setting
          {
            path: "list",
            name: "inventories-list",
            component: () => import("@/view/pages/inventories/List.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "add",
            name: "inventories-add",
            component: () => import("@/view/pages/inventories/Add.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "edit/:id",
            name: "inventories-edit",
            component: () => import("@/view/pages/inventories/Edit.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "detail/:id",
            name: "inventories-detail",
            component: () => import("@/view/pages/inventories/Detail.vue"),
            meta: {
              title: "SIMDES | Inventori",
            },
          },
        ],
      },

      // inventory-stocks
      {
        path: "/inventory-stocks",
        name: "inventory-stocks",
        redirect: "/inventory-stocks/list",
        component: () =>
          import("@/view/pages/inventory-stocks/InventoryStock.vue"),
        meta: {
          title: "SIDARIN | Inventori",
        },
        children: [
          // setting
          {
            path: "list",
            name: "inventory-stocks-list",
            component: () => import("@/view/pages/inventory-stocks/List.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "add",
            name: "inventory-stocks-add",
            component: () => import("@/view/pages/inventory-stocks/Add.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "edit/:id",
            name: "inventory-stocks-edit",
            component: () => import("@/view/pages/inventory-stocks/Edit.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "detail/:id",
            name: "inventory-stocks-detail",
            component: () => import("@/view/pages/inventory-stocks/Detail.vue"),
            meta: {
              title: "SIMDES | Inventori",
            },
          },
        ],
      },

      // material-categories
      {
        path: "/material-categories",
        name: "material-categories",
        redirect: "/material-categories/list",
        component: () =>
          import("@/view/pages/material-categories/MaterialCategory.vue"),
        meta: {
          title: "SIDARIN | Kategori Persediaan Bahan",
        },
        children: [
          // setting
          {
            path: "list",
            name: "material-categories-list",
            component: () => import("@/view/pages/material-categories/List.vue"),
            meta: {
              title: "SIDARIN | Kategori Persediaan Bahan",
            },
          },
          {
            path: "add",
            name: "material-categories-add",
            component: () => import("@/view/pages/material-categories/Add.vue"),
            meta: {
              title: "SIDARIN | Kategori Persediaan Bahan",
            },
          },
          {
            path: "edit/:id",
            name: "material-categories-edit",
            component: () => import("@/view/pages/material-categories/Edit.vue"),
            meta: {
              title: "SIDARIN | Kategori Persediaan Bahan",
            },
          },
          {
            path: "detail/:id",
            name: "material-categories-detail",
            component: () => import("@/view/pages/material-categories/Detail.vue"),
            meta: {
              title: "SIMDES | Kategori Persediaan Bahan",
            },
          },
        ],
      },

      // inventories
      {
        path: "/materials",
        name: "materials",
        redirect: "/materials/list",
        component: () =>
          import("@/view/pages/materials/Material.vue"),
        meta: {
          title: "SIDARIN | Persediaan Bahan",
        },
        children: [
          // setting
          {
            path: "list",
            name: "materials-list",
            component: () => import("@/view/pages/materials/List.vue"),
            meta: {
              title: "SIDARIN | Persediaan Bahan",
            },
          },
          {
            path: "add",
            name: "materials-add",
            component: () => import("@/view/pages/materials/Add.vue"),
            meta: {
              title: "SIDARIN | Persediaan Bahan",
            },
          },
          {
            path: "edit/:id",
            name: "materials-edit",
            component: () => import("@/view/pages/materials/Edit.vue"),
            meta: {
              title: "SIDARIN | Persediaan Bahan",
            },
          },
          {
            path: "detail/:id",
            name: "materials-detail",
            component: () => import("@/view/pages/materials/Detail.vue"),
            meta: {
              title: "SIMDES | Persediaan Bahan",
            },
          },
        ],
      },

      // material-stocks
      {
        path: "/material-stocks",
        name: "material-stocks",
        redirect: "/material-stocks/list",
        component: () =>
          import("@/view/pages/material-stocks/MaterialStock.vue"),
        meta: {
          title: "SIDARIN | Inventori",
        },
        children: [
          // setting
          {
            path: "list",
            name: "material-stocks-list",
            component: () => import("@/view/pages/material-stocks/List.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "add",
            name: "material-stocks-add",
            component: () => import("@/view/pages/material-stocks/Add.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "edit/:id",
            name: "material-stocks-edit",
            component: () => import("@/view/pages/material-stocks/Edit.vue"),
            meta: {
              title: "SIDARIN | Inventori",
            },
          },
          {
            path: "detail/:id",
            name: "material-stocks-detail",
            component: () => import("@/view/pages/material-stocks/Detail.vue"),
            meta: {
              title: "SIMDES | Inventori",
            },
          },
        ],
      },

      // material-distributions
      {
        path: "/material-distributions",
        name: "material-distributions",
        redirect: "/material-distributions/list",
        component: () =>
          import("@/view/pages/material-distributions/MaterialDistribution.vue"),
        meta: {
          title: "SIDARIN | Distribusi Bansos",
        },
        children: [
          // setting
          {
            path: "list",
            name: "material-distributions-list",
            component: () => import("@/view/pages/material-distributions/List.vue"),
            meta: {
              title: "SIDARIN | Distribusi Bansos",
            },
          },
          {
            path: "add",
            name: "material-distributions-add",
            component: () => import("@/view/pages/material-distributions/Add.vue"),
            meta: {
              title: "SIDARIN | Distribusi Bansos",
            },
          },
          // {
          //   path: "edit/:id",
          //   name: "material-distributions-edit",
          //   component: () => import("@/view/pages/material-distributions/Edit.vue"),
          //   meta: {
          //     title: "SIDARIN | Distribusi Bansos",
          //   },
          // },
          {
            path: "detail/:id",
            name: "material-distributions-detail",
            component: () => import("@/view/pages/material-distributions/Detail.vue"),
            meta: {
              title: "SIMDES | Distribusi Bansos",
            },
          },
        ],
      },

      // expense-categories
      {
        path: "/expense-categories",
        name: "expense-categories",
        redirect: "/expense-categories/list",
        component: () =>
          import("@/view/pages/expense-categories/ExpenseCategory.vue"),
        meta: {
          title: "SIDARIN | Kategori Pengeluaran",
        },
        children: [
          // setting
          {
            path: "list",
            name: "expense-categories-list",
            component: () => import("@/view/pages/expense-categories/List.vue"),
            meta: {
              title: "SIDARIN | Kategori Pengeluaran",
            },
          },
          {
            path: "add",
            name: "expense-categories-add",
            component: () => import("@/view/pages/expense-categories/Add.vue"),
            meta: {
              title: "SIDARIN | Kategori Pengeluaran",
            },
          },
          {
            path: "edit/:id",
            name: "expense-categories-edit",
            component: () => import("@/view/pages/expense-categories/Edit.vue"),
            meta: {
              title: "SIDARIN | Kategori Pengeluaran",
            },
          },
          {
            path: "detail/:id",
            name: "expense-categories-detail",
            component: () => import("@/view/pages/expense-categories/Detail.vue"),
            meta: {
              title: "SIMDES | Kategori Pengeluaran",
            },
          },
        ],
      },

      // expenses
      {
        path: "/expenses",
        name: "expenses",
        redirect: "/expenses/list",
        component: () =>
          import("@/view/pages/expenses/Expense.vue"),
        meta: {
          title: "SIDARIN | Kategori Pengeluaran",
        },
        children: [
          // setting
          {
            path: "list",
            name: "expenses-list",
            component: () => import("@/view/pages/expenses/List.vue"),
            meta: {
              title: "SIDARIN | Pengeluaran",
            },
          },
          {
            path: "add",
            name: "expenses-add",
            component: () => import("@/view/pages/expenses/Add.vue"),
            meta: {
              title: "SIDARIN | Pengeluaran",
            },
          },
          {
            path: "edit/:id",
            name: "expenses-edit",
            component: () => import("@/view/pages/expenses/Edit.vue"),
            meta: {
              title: "SIDARIN | Pengeluaran",
            },
          },
          {
            path: "detail/:id",
            name: "expenses-detail",
            component: () => import("@/view/pages/expenses/Detail.vue"),
            meta: {
              title: "SIMDES | Pengeluaran",
            },
          },
          {
            path: "report",
            name: "expenses-report",
            component: () => import("@/view/pages/expenses/Report.vue"),
            meta: {
              title: "SIDARIN | Pengeluaran",
            },
          },
        ],
      },

      // ::masters::
      {
        path: "/masters",
        name: "masters",
        redirect: "/masters/settings",
        component: () => import("@/view/pages/masters/Masters.vue"),
        meta: {
          title: "SIDARIN | Data Pendukung - Setting",
        },
        children: [
          // setting
          {
            path: "/masters/settings",
            name: "master-setting",
            component: () => import("@/view/pages/masters/Setting.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Setting",
            },
          },

          // access-roles
          {
            path: "/masters/user-managements/role-access",
            name: "master-access-roles",
            component: () =>
              import("@/view/pages/masters/user-managements/RoleAccess.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Hak Akses Peran",
              access_right: "9001",
            },
          },

          // access-users
          {
            path: "/masters/user-managements/user-access",
            name: "master-access-users",
            component: () =>
              import("@/view/pages/masters/user-managements/UserAccess.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Hak Akses User",
              access_right: "9002",
            },
          },
          // roles
          {
            path: "/masters/roles",
            name: "master-roles",
            component: () => import("@/view/pages/masters/roles/Roles.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Role",
              access_right: "9027",
            },
          },
          {
            path: "/masters/roles/add",
            name: "master-roles-add",
            component: () => import("@/view/pages/masters/roles/RolesAdd.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Role",
              access_right: "9028",
            },
          },
          {
            path: "/masters/roles/edit/:id",
            name: "master-roles-edit",
            component: () =>
              import("@/view/pages/masters/roles/RolesUpdate.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Peran",
              access_right: "9028",
            },
          },
          // roles
          {
            path: "/masters/roles",
            name: "master-roles",
            component: () => import("@/view/pages/masters/roles/Roles.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Role",
              access_right: "9027",
            },
          },
          {
            path: "/masters/roles/add",
            name: "master-roles-add",
            component: () => import("@/view/pages/masters/roles/RolesAdd.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Role",
              access_right: "9028",
            },
          },
          {
            path: "/masters/roles/edit/:id",
            name: "master-roles-edit",
            component: () =>
              import("@/view/pages/masters/roles/RolesUpdate.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Peran",
              access_right: "9028",
            },
          },

          // citizen report category
          {
            path: "/masters/category-citizen-report",
            name: "master-category-citizen-report",
            component: () =>
              import("@/view/pages/masters/categoryCitizenReport/List.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Pengaduan",
              access_right: "9027",
            },
          },
          {
            path: "/masters/category-citizen-report/add",
            name: "master-category-citizen-report-add",
            component: () =>
              import("@/view/pages/masters/categoryCitizenReport/Add.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Pengaduan",
              access_right: "9028",
            },
          },
          {
            path: "/masters/category-citizen-report/edit/:id",
            name: "master-category-citizen-report-edit",
            component: () =>
              import("@/view/pages/masters/categoryCitizenReport/Update.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Pengaduan",
              access_right: "9028",
            },
          },
          {
            path: "/masters/category-citizen-report/detail/:id",
            name: "master-category-citizen-report-detail",
            component: () =>
              import("@/view/pages/masters/categoryCitizenReport/Detail.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Pengaduan",
              access_right: "9027",
            },
          },

          // infrastructure category
          {
            path: "/masters/infrastructure-category",
            name: "master-infrastructure-category",
            component: () =>
              import("@/view/pages/masters/categoryInfrastructure/List.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Jenis Sarana",
              access_right: "9027",
            },
          },
          {
            path: "/masters/infrastructure-category/add",
            name: "master-infrastructure-category-add",
            component: () =>
              import("@/view/pages/masters/categoryInfrastructure/Add.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Jenis Sarana",
              access_right: "9028",
            },
          },
          {
            path: "/masters/infrastructure-category/edit/:id",
            name: "master-infrastructure-category-edit",
            component: () =>
              import("@/view/pages/masters/categoryInfrastructure/Update.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Jenis Sarana",
              access_right: "9028",
            },
          },
          {
            path: "/masters/infrastructure-category/detail/:id",
            name: "master-infrastructure-category-detail",
            component: () =>
              import("@/view/pages/masters/categoryInfrastructure/Detail.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Jenis Sarana",
              access_right: "9027",
            },
          },

          // news category
          {
            path: "/masters/news-category",
            name: "master-news-category",
            component: () =>
              import("@/view/pages/masters/categoryNews/List.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Berita",
              access_right: "9027",
            },
          },
          {
            path: "/masters/news-category/add",
            name: "master-news-category-add",
            component: () =>
              import("@/view/pages/masters/categoryNews/Add.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Berita",
              access_right: "9028",
            },
          },
          {
            path: "/masters/news-category/edit/:id",
            name: "master-news-category-edit",
            component: () =>
              import("@/view/pages/masters/categoryNews/Update.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Berita",
              access_right: "9028",
            },
          },
          {
            path: "/masters/news-category/detail/:id",
            name: "master-news-category-detail",
            component: () =>
              import("@/view/pages/masters/categoryNews/Detail.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Berita",
              access_right: "9027",
            },
          },

          // village business category
          {
            path: "/masters/village-business-category",
            name: "master-village-business-category",
            component: () =>
              import("@/view/pages/masters/categoryVillageBusiness/List.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Bidang Usaha",
              access_right: "9027",
            },
          },
          {
            path: "/masters/village-business-category/add",
            name: "master-village-business-category-add",
            component: () =>
              import("@/view/pages/masters/categoryVillageBusiness/Add.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Bidang Usaha",
              access_right: "9028",
            },
          },
          {
            path: "/masters/village-business-category/edit/:id",
            name: "master-village-business-category-edit",
            component: () =>
              import("@/view/pages/masters/categoryVillageBusiness/Update.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Bidang Usaha",
              access_right: "9028",
            },
          },
          {
            path: "/masters/village-business-category/detail/:id",
            name: "master-village-business-category-detail",
            component: () =>
              import("@/view/pages/masters/categoryVillageBusiness/Detail.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Bidang Usaha",
              access_right: "9027",
            },
          },

          // users
          {
            path: "/masters/users",
            name: "master-users",
            component: () => import("@/view/pages/masters/users/Users.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - User",
              access_right: "9001",
            },
          },
          {
            path: "/masters/users/add",
            name: "master-users-add",
            component: () => import("@/view/pages/masters/users/Add.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - User",
              access_right: "9002",
            },
          },
          {
            path: "/masters/users/detail/:id",
            name: "master-users-detail",
            component: () => import("@/view/pages/masters/users/Detail.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - User",
              access_right: "9001",
            },
          },
          {
            path: "/masters/users/edit/:id",
            name: "master-users-edit",
            component: () => import("@/view/pages/masters/users/Update.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - User",
              access_right: "9002",
            },
          },
          {
            path: "/masters/urban-village",
            name: "urban-village",
            redirect: "/masters/urban-village/list",
            component: () =>
              import("@/view/pages/masters/urban-village/UrbanVillage.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Dusun",
            },
            children: [
              {
                path: "/masters/urban-village/list",
                name: "master-urban-village-list",
                component: () =>
                  import("@/view/pages/masters/urban-village/List.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/urban-village/add",
                name: "master-urban-village-add",
                component: () =>
                  import("@/view/pages/masters/urban-village/Add.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/urban-village/edit/:id",
                name: "master-urban-village-edit",
                component: () =>
                  import("@/view/pages/masters/urban-village/Edit.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/urban-village/detail/:id",
                name: "master-urban-village-detail",
                component: () =>
                  import("@/view/pages/masters/urban-village/Detail.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
            ],
          },
          {
            path: "/masters/profession",
            name: "profession",
            redirect: "/masters/profession/list",
            component: () =>
              import("@/view/pages/masters/profession/Profession.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Pekerjaan",
            },
            children: [
              {
                path: "/masters/profession/list",
                name: "master-profession-list",
                component: () =>
                  import("@/view/pages/masters/profession/List.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Pekerjaan",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/profession/add",
                name: "master-profession-add",
                component: () =>
                  import("@/view/pages/masters/profession/Add.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Pekerjaan",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/profession/edit/:id",
                name: "master-profession-edit",
                component: () =>
                  import("@/view/pages/masters/profession/Edit.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Pekerjaan",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/profession/detail/:id",
                name: "master-profession-detail",
                component: () =>
                  import("@/view/pages/masters/profession/Detail.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Pekerjaan",
                  access_right: "9002",
                },
              },
            ],
          },
          {
            path: "/masters/hamlets",
            name: "hamlets",
            redirect: "/masters/hamlets/list",
            component: () => import("@/view/pages/masters/hamlets/Hamlet.vue"),
            meta: {
              title: "SIDARIN | Data Pendukung - Dusun",
            },
            children: [
              {
                path: "/masters/hamlets/list",
                name: "master-hamlets-list",
                component: () =>
                  import("@/view/pages/masters/hamlets/List.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/hamlets/add",
                name: "master-hamlets-add",
                component: () => import("@/view/pages/masters/hamlets/Add.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/hamlets/edit/:id",
                name: "master-hamlets-edit",
                component: () =>
                  import("@/view/pages/masters/hamlets/Edit.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/hamlets/detail/:id",
                name: "master-hamlets-detail",
                component: () =>
                  import("@/view/pages/masters/hamlets/Detail.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Dusun",
                  access_right: "9002",
                },
              },
            ],
          },
          {
            path: "/masters/law-product-categories",
            name: "law-product-categories",
            redirect: "/masters/law-product-categories/list",
            component: () =>
              import(
                "@/view/pages/masters/law-product-categories/LawProductCategory.vue"
              ),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Produk Hukum",
            },
            children: [
              {
                path: "/masters/law-product-categories/list",
                name: "master-law-product-categories-list",
                component: () =>
                  import(
                    "@/view/pages/masters/law-product-categories/List.vue"
                  ),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Produk Hukum",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/law-product-categories/add",
                name: "master-law-product-categories-add",
                component: () =>
                  import("@/view/pages/masters/law-product-categories/Add.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Produk Hukum",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/law-product-categories/edit/:id",
                name: "master-law-product-categories-edit",
                component: () =>
                  import(
                    "@/view/pages/masters/law-product-categories/Edit.vue"
                  ),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Produk Hukum",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/law-product-categories/detail/:id",
                name: "master-law-product-categories-detail",
                component: () =>
                  import(
                    "@/view/pages/masters/law-product-categories/Detail.vue"
                  ),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Produk Hukum",
                  access_right: "9002",
                },
              },
            ],
          },
          {
            path: "/masters/announcements",
            name: "announcements",
            redirect: "/masters/announcements/list",
            component: () =>
              import(
                "@/view/pages/masters/announcements/Announcement.vue"
              ),
            meta: {
              title: "SIDARIN | Data Pendukung - Kategori Pengumuman",
            },
            children: [
              {
                path: "/masters/announcements/list",
                name: "master-announcements-list",
                component: () =>
                  import(
                    "@/view/pages/masters/announcements/List.vue"
                  ),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Pengumuman",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/announcements/add",
                name: "master-announcements-add",
                component: () =>
                  import("@/view/pages/masters/announcements/Add.vue"),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Pengumuman",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/announcements/edit/:id",
                name: "master-announcements-edit",
                component: () =>
                  import(
                    "@/view/pages/masters/announcements/Edit.vue"
                  ),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Pengumuman",
                  access_right: "9002",
                },
              },
              {
                path: "/masters/announcements/detail/:id",
                name: "master-announcements-detail",
                component: () =>
                  import(
                    "@/view/pages/masters/announcements/Detail.vue"
                  ),
                meta: {
                  title: "SIDARIN | Data Pendukung - Kategori Pengumuman",
                  access_right: "9002",
                },
              },
            ],
          },
        ],
      },

      // blankboard
      {
        path: "/blankboard",
        name: "blankboard",
        component: () => import("@/view/pages/Blankboard.vue"),
        meta: {
          title: "Blankboard",
        },
      },
      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/view/pages/custom_pages/Profile.vue"),
        meta: {
          title: "SIDARIN | Profil",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Login"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: "SIDARIN | Login",
        },
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: "SIDARIN | Register",
        },
      },
    ],
  },
  {
    path: "/website-component",
    component: () =>
      import("@/view/pages/website-component/PengaduanMasyarakat.vue"),
    children: [
      {
        name: "pengaduan-masyarakat",
        path: "/website-component/pengaduan-masyarakat",
        component: () =>
          import("@/view/pages/website-component/PengaduanMasyarakat.vue"),
        meta: {
          title: "SIDARIN | Components",
        },
      },
    ],
  },
  // statistic
  {
    path: "/map",
    name: "map",
    component: () => import("@/view/pages/geolocation/Map.vue"),
    meta: {
      title: "SIDARIN | geo",
    },
  },
  {
    path: "/approvement",
    name: "approvement",
    meta: {
      title: "SIDARIN | Persetujuan Surat",
    },
    component: () => import("@/view/pages/approvement/Page.vue"),
  },
  {
    path: "/approvement-village-chief",
    name: "approvement-village-chief",
    meta: {
      title: "SIDARIN | Persetujuan Surat",
    },
    component: () => import("@/view/pages/approvement/PageVillageChief.vue"),
  },
  {
    path: "/activation",
    name: "activation",
    meta: {
      title: "SIDARIN | Aktivasi Akun",
    },
    component: () => import("@/view/pages/activation/Page.vue"),
  },
  {
    path: "/anjungan-mandiri",
    name: "anjungan-mandiri",
    redirect: "/anjungan-mandiri/main",
    component: () =>
      import("@/view/pages/anjungan-mandiri/AnjunganMandiri.vue"),
    meta: {
      title: "SIDARIN | Anjungan Mandiri",
    },
    children: [
      {
        path: "/anjungan-mandiri/main-2",
        name: "anjungan-mandiri-main-2",
        component: () => import("@/view/pages/anjungan-mandiri/Main.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/main",
        name: "anjungan-mandiri-main",
        component: () => import("@/view/pages/anjungan-mandiri/Main2.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/layanan-mandiri",
        name: "anjungan-mandiri-layanan-mandiri",
        component: () =>
          import("@/view/pages/anjungan-mandiri/LayananMandiri.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/surat-menyurat",
        name: "anjungan-mandiri-surat-menyurat",
        component: () =>
          import("@/view/pages/anjungan-mandiri/SuratMenyurat.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/pengaduan-masyarakat",
        name: "anjungan-mandiri-pengaduan-masyarakat",
        component: () =>
          import("@/view/pages/anjungan-mandiri/PengaduanMasyarakat.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/statistik-desa",
        name: "anjungan-mandiri-statistik-desa",
        component: () =>
          import("@/view/pages/anjungan-mandiri/StatistikDesa.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/profil-desa",
        name: "anjungan-mandiri-profil-desa",
        component: () => import("@/view/pages/anjungan-mandiri/InfoDesa.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/peta-desa",
        name: "anjungan-mandiri-peta-desa",
        component: () => import("@/view/pages/anjungan-mandiri/PetaDesa.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/kontak-desa",
        name: "anjungan-mandiri-kontak-desa",
        component: () => import("@/view/pages/anjungan-mandiri/KontakDesa.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/berita-desa",
        name: "anjungan-mandiri-berita-desa",
        component: () => import("@/view/pages/anjungan-mandiri/BeritaDesa.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/anjungan-mandiri/berita-desa/:id",
        name: "anjungan-mandiri-berita-desa-detail",
        component: () =>
          import("@/view/pages/anjungan-mandiri/DetailBeritaDesa.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
    ],
  },
  {
    path: "/surat-menyurat",
    name: "surat-menyurat",
    redirect: "/surat-menyurat/keterangan-penduduk",
    component: () => import("@/view/pages/surat-menyurat/SuratMenyurat.vue"),
    meta: {
      title: "SIDARIN | Anjungan Mandiri",
    },
    children: [
      {
        path: "/surat-menyurat/keterangan-penduduk",
        name: "surat-menyurat-keterangan-penduduk",
        component: () =>
          import("@/view/pages/surat-menyurat/KeteranganPenduduk.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/ijin-usaha",
        name: "surat-menyurat-ijin-usaha",
        component: () => import("@/view/pages/surat-menyurat/IjinUsaha.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/domisili-usaha",
        name: "surat-menyurat-domisili-usaha",
        component: () =>
          import("@/view/pages/surat-menyurat/DomisiliUsaha.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/surat-kematian",
        name: "surat-menyurat-surat-kematian",
        component: () =>
          import("@/view/pages/surat-menyurat/SuratKematian.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/surat-kelahiran",
        name: "surat-menyurat-surat-kelahiran",
        component: () =>
          import("@/view/pages/surat-menyurat/SuratKelahiran.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/surat-pindah",
        name: "surat-menyurat-surat-pindah",
        component: () => import("@/view/pages/surat-menyurat/SuratPindah.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/keterangan-jamkes",
        name: "surat-menyurat-keterangan-jamkes",
        component: () =>
          import("@/view/pages/surat-menyurat/KeteranganJamkes.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/pengantar-ktp",
        name: "surat-menyurat-pengantar-ktp",
        component: () => import("@/view/pages/surat-menyurat/PengantarKTP.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/keterangan-kurang-mampu",
        name: "surat-menyurat-keterangan-kurang-mampu",
        component: () =>
          import("@/view/pages/surat-menyurat/KeteranganKurangMampu.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/keterangan-kelakuan-baik",
        name: "surat-menyurat-keterangan-kelakuan-baik",
        component: () => import("@/view/pages/surat-menyurat/KelakuanBaik.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/keterangan-jkbm",
        name: "surat-menyurat-keterangan-jkbm",
        component: () =>
          import("@/view/pages/surat-menyurat/KeteranganJKBM.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
      {
        path: "/surat-menyurat/keterangan-belum-kawin",
        name: "surat-menyurat-keterangan-belum-kawin",
        component: () => import("@/view/pages/surat-menyurat/BelumKawin.vue"),
        meta: {
          title: "SIDARIN | Anjungan Mandiri",
        },
      },
    ],
  },
  // iframe component
  // News
  {
    path: "/iframe",
    name: "iframe",
    redirect: "/iframe/good-conduct-certificate",
    component: () => import("@/view/components/iframe/Iframe.vue"),
    meta: {
      title: "SIDARIN | Komponen",
    },
    children: [
      // setting
      {
        path: "/iframe/good-conduct-certificate",
        name: "iframe-good-conduct-certificate",
        component: () =>
          import("@/view/components/iframe/GoodConductCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Kelakuan Baik",
        },
      },
      {
        path: "/iframe/health-insurance-certificate",
        name: "iframe-health-insurance-certificate",
        component: () =>
          import("@/view/components/iframe/HealthInsuranceCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Jamkes",
        },
      },
      {
        path: "/iframe/cover-letter",
        name: "iframe-cover-letter",
        component: () => import("@/view/components/iframe/CoverLetter2.vue"),
        meta: {
          title: "SIDARIN | Surat Pengantar KTP",
        },
      },
      {
        path: "/iframe/poverty-certificate",
        name: "iframe-poverty-certificate",
        component: () =>
          import("@/view/components/iframe/PovertyCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Kurang Mampu",
        },
      },
      {
        path: "/iframe/jkbm-certificate",
        name: "iframe-jkbm-certificate",
        component: () =>
          import("@/view/components/iframe/JKBMCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan JKBM",
        },
      },
      {
        path: "/iframe/resident-certificate",
        name: "iframe-resident-certificate",
        component: () =>
          import("@/view/components/iframe/ResidentCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Penduduk",
        },
      },
      {
        path: "/iframe/birth-certificate",
        name: "iframe-birth-certificate",
        component: () =>
          import("@/view/components/iframe/BirthCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Kelahiran",
        },
      },
      {
        path: "/iframe/unmarried-certificate",
        name: "iframe-unmarried-certificate",
        component: () =>
          import("@/view/components/iframe/UnmarriedCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Belum Kawin",
        },
      },
      {
        path: "/iframe/death-certificate",
        name: "iframe-death-certificate",
        component: () =>
          import("@/view/components/iframe/DeathCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Kematian",
        },
      },
      {
        path: "/iframe/bussiness-certificate",
        name: "iframe-bussiness-certificate",
        component: () =>
          import("@/view/components/iframe/BussinessCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Ijin Usaha",
        },
      },
      {
        path: "/iframe/bussiness-place-certificate",
        name: "iframe-bussiness-place-certificate",
        component: () =>
          import("@/view/components/iframe/BussinessPlaceCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Domisili Usaha",
        },
      },
      {
        path: "/iframe/transfer-certificate",
        name: "iframe-transfer-certificate",
        component: () =>
          import("@/view/components/iframe/TransferCertificate2.vue"),
        meta: {
          title: "SIDARIN | Surat Keterangan Pidah",
        },
      },
      {
        path: "/iframe/general-certificate",
        name: "iframe-general-certificate",
        component: () =>
          import("@/view/components/iframe/GeneralCertificate.vue"),
        meta: {
          title: "SIDARIN | Surat Umum",
        },
      },
      {
        path: "/iframe/law-product",
        name: "iframe-law-product",
        component: () => import("@/view/components/iframe/LawProduct.vue"),
        meta: {
          title: "SIDARIN | Produk Hukum",
        },
      }, 
      {
        path: "/iframe/menu-surat-menyurat",
        name: "iframe-menu-surat-menyurat",
        component: () => import("@/view/components/iframe/MenuSuratMenyurat.vue"),
        meta: {
          title: 'SIDARIN | Surat Menyurat'
        },
      }, 
    ]
  },

  // statistic-religion
  {
    path: "/statistic/religion",
    name: "statistic-religion",
    component: () => import("@/view/pages/statistic/ReligionStatistic.vue"),
    meta: {
      title: "SIDARIN | Statistik",
    },
  },

  // statistic-website
  {
    path: "/statistic/website",
    name: "statistic-website",
    component: () =>
      import("@/view/pages/anjungan-mandiri/StatistikDesaWebsite.vue"),
    meta: {
      title: "SIDARIN | Statistik",
    },
  },

  // redirect to 404
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      title: "SIDARIN | Page Not Found",
    },
    component: () => import("@/view/pages/error/Error-1.vue"),
  },

  // 503
  {
    path: "/access-forbidden",
    name: "access-forbidden",
    meta: {
      title: "SIDARIN | Access Forbidden",
    },
    component: () => import("@/view/pages/error/Error-7.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// Route Middleware
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.access_right)) {
//     let localStorageAccessRight = JSON.parse(localStorage.getItem('access_right_display'))
//     if (localStorageAccessRight != null) {
//       let result = false
//       for (let i = 0; i < localStorageAccessRight.length; i++) {
//         result = false
//         if (localStorageAccessRight[i] == to.meta.access_right) {
//           result = true
//           break
//         }
//       }
//       if (result) {
//         next()
//       } else {
//         next('/access-forbidden')
//       }
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router;
